import React from "react"
import Img from "gatsby-image"

const PostMobileWideScreen = ({ dynamicHeight, data }) => {
  return (
    <div className="wheel-element">
      <div className="gallery-wrapper">
        <div className="gallery-container">
          {data.map(edge => {
            const { frontmatter, id } = edge.node
            const { galleria } = frontmatter

            const extraSize = 1.3

            return (
              <div key={id} className="post-wrapper">
                <div className="image-gallery">
                  <ul>
                    {galleria.map(image => {
                      const imgRatio =
                        image.imgSrc.childImageSharp.fluid.aspectRatio
                      const dynamicWidth = imgRatio * dynamicHeight

                      return (
                        <li
                          key={image.imgSrc.childImageSharp.id}
                          style={{ width: dynamicWidth * extraSize }}
                        >
                          <Img
                            fluid={image.imgSrc.childImageSharp.fluid}
                            style={{
                              height: dynamicHeight * extraSize,
                              width: dynamicWidth * extraSize,
                            }}
                          />
                        </li>
                      )
                    })}
                    {galleria.map((image, i) => {
                      return (
                        <div>
                          {i === 0 && frontmatter?.title ? (
                            <p className="image-title">
                              <strong>{frontmatter.title}</strong>
                            </p>
                          ) : null}
                          {!image.imgDesc ||
                          !image.imgDesc.trim().length ? null : (
                            <p
                              style={{ width: 300 }}
                              className="image-description"
                            >
                              {image.imgDesc}
                            </p>
                          )}
                        </div>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PostMobileWideScreen
